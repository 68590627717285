.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

.header__menu {
  background-color: none;
}

.header__link,
.header__link:hover {
  background-color: none;
  text-decoration: none;
}

.verse__container {
  display: inline-flex;
  position: relative;
  /* background-color: #272727; */
}

.verse__number {
  font-size: 8px;
  position: absolute;
  margin-top: -4px;
  margin-left: -5px;
}

.result__number {
  font-size: "1rem";
  position: relative;
  display: flex;
  margin: "1rem";
}

.result__total {
  display: "flex";
  position: relative;
  margin: "1rem";
}

.verse__text {
  align-items: normal;
  margin: none;
  padding: none;
  text-align: left;
  display: inline;
  width: auto;
  box-shadow: none;
  border: none;
  background-color: transparent;
  font-family: "EB Garamond";
  font-weight: 200;
}

.verse__selected {
  border-left: 1px dashed #fff;
  border-top: 1px dashed #fff;
  border-right: 1px dashed #fff;
}

/* Styles for the login page */
.login-container {
  text-align: center;
  margin-top: 50px;
}

.login-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Modal Styles */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  margin: auto;
  text-align: center;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Buttons inside the modal */
.google-login, .email-login {
  background-color: #4285F4;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 10px;
  font-size: 16px;
  border-radius: 5px;
}

.google-login:hover, .email-login:hover {
  background-color: #3367d6;
}

.close-modal {
  background-color: #ccc;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

/* YouTube css overrides */
/* .ytp-title-beacon, */
/* .ytp-gradient-top, */
/* .ytp-gradient-top, */
.ytp-title-expanded-overlay,
.ytp-title-expanded-heading,
.ytp-title,
.ytp-shorts-title-channel,
.ytp-chrome-top-buttons,
.ytp-unmute,
.ytp-player-content,
.ytp-cued-thumbnail-overlay,
.ytp-cued-thumbnail-overlay-image,
.ytp-large-play-button,
.ytp-spinner,
.ytp-paid-content-overlay,
.ytp-doubletap-ui-legacy,
.ytp-suggested-action,
.ytp-info-panel-preview,
.ytp-pause-overlay-container,
.ytp-muted-autoplay-overlay,
.ytp-muted-autoplay-endscreen-overlay,
.ytp-playlist-menu,
.ytp-share-panel,
.ytp-info-panel-detail-skrim,
.ytp-watermark,
.ytp-title-link {
  display: none !important;
}

:root {
  --highlight-color: yellow;
}

.highlight span.verse__text {
  background-color: var(--highlight-color) !important;
  color: black;
}

.light-svg {
  filter: invert(0.75) contrast(1.65);
  -webkit-font-smoothing: subpixel-antialiased;
}

.dark-svg {
  filter: invert(0.75) contrast(1.9);
  -webkit-font-smoothing: subpixel-antialiased;
}

.svg__sub-heading {
  position: relative;
  font-size: 30px;
  z-index: 1;
  overflow: hidden;
  text-align: center;
}

.svg__sub-heading:before, .svg__sub-heading:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  height: 1px;
  content: '\a0';
  background-color: #a1a1a1;
}

.svg__sub-heading:before {
  width: 25%;
  margin-left: -25%;
  text-align: right;
}

.svg__sub-heading:after {
  width: 25%;
  margin-right: 1%;
}

/* #svg-jesus {
  filter: invert(1) contrast(2);
  -webkit-font-smoothing: subpixel-antialiased;
  opacity: 0.75;
} */
